import React from 'react'

function CurrencyComponent({amount}) {
  let resultFormatted = `$${amount.toLocaleString(undefined, {maximumFractionDigits: 0})}`
  let resultClassName = 'currency'
  if (amount < 0) {
    resultClassName = 'currency negative'
  }
  return (
    <span className={resultClassName}>{resultFormatted}</span>
  )
}

export default CurrencyComponent