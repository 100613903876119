import React, { useContext } from "react";
import AppContext from "../context/AppContext";
import PlaceResultsComponent from "./PlaceResultsComponent";
import MultiResultsComponent from "./MultiResultsComponent";


function ResultsByEntrantComponent({marketGroup}) {
  const {displayHold, displayBonusHold, displayLiability, displayResult, displayAvgLay, scratchings } = useContext(AppContext)

  let resultsByEntrant = {}

  console.log(marketGroup)

  let marketName = marketGroup.market
  let results = marketGroup.entrants

  let totalHold = results.reduce((a, b) => a + b.Hold, 0)
  
  results.forEach((result) => {
    let key = result.EntrantNumber.toString() + ". " + result.EntrantName
    if (resultsByEntrant[key] === undefined) {
      resultsByEntrant[key] = []
    }

    resultsByEntrant[key].push(result)
  })

  let sortedResultsByEntrant = {}
  Object.keys(resultsByEntrant).sort((a, b) => {
    //return a < b.EntrantNumber
    return a.localeCompare(b, 'en', {numeric: true})
  }).forEach(function(key) {
    sortedResultsByEntrant[key] = resultsByEntrant[key];
  });
  
  const getUpdateKey = (entrantName, mode) => {
    return (entrantName + "-" + mode).replaceAll(" ","-").replaceAll(".", "")
  }
  
  const getClassName = (entrant, extra = []) => {
    let entrantKey = "entrant-" + entrant.replaceAll(" ","-").replaceAll(".", "")
    extra.push("entrant")
    extra.push(entrantKey)

    let entrantRec = sortedResultsByEntrant[entrant][0]

    if (typeof(scratchings[entrantRec.EntrantID]) !== "undefined") {
      extra.push("scratched")
    }

    return extra.join(" ")
  }

  const toggleHover = (event) => {
    let target = event.target
    let entrant = target.closest('.entrant')
    if (entrant === null) {
      return
    }
    let entrantName = entrant.classList[1]

    document.querySelectorAll('.entrant').forEach((element) => {
      if (element.classList.contains(entrantName) === false) {
        element.classList.remove('hover')
      } else {
        element.classList.add('hover')
      }
    })
  }

  const removeHover = () => {
    document.querySelectorAll('.entrant').forEach((element) => {
      element.classList.remove('hover')
    })
  }

  let winColClass = "col-md-5 panel win"

  if (marketName !== "Final Field") {
    winColClass = "col-md-5 panel win"
  }

  return (
    <div className="row market">
      <div className="col-md-12">
        <h4>{marketName}</h4>
        <div className="row">
          <div className={winColClass}>
            <h4>Win</h4>
            <table className="table table-striped table-win" onMouseLeave={removeHover}>
              <thead>
                <tr>
                  <th>Entrant</th>
                  <th>Avg Lay</th>
                  <th>Fixed Hold</th>
                  {/*<th>Fixed Lia</th>*/}
                  <th>Fixed Result</th>
                  <th>Tote Hold</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(sortedResultsByEntrant).map(([entrant, entrantResults]) => (
                  <tr className={getClassName(entrant)} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                    <td align="left" className='entrant-name'>{entrant}</td>
                    <td align="right" className={getUpdateKey(entrant, "fixedwinlay")}>{displayAvgLay(getUpdateKey(entrant, "fixedwinlay"), "FixedWin", entrantResults)}</td>
                    <td align="right" className={getUpdateKey(entrant, "fixedwin")}>{displayHold(getUpdateKey(entrant, "fixedwin"), "FixedWin", entrantResults)} {displayBonusHold(getUpdateKey(entrant, 'fixedwin'), 'FixedWin', entrantResults)}</td>
                    {/*<td align="right" className={getUpdateKey(entrant, "fixedwinliability")}>{displayLiability(getUpdateKey(entrant, "fixedwinresult"), "FixedWin", entrantResults)}</td>*/}
                    <td align="right" className={getUpdateKey(entrant, "fixedwinresult")}>{displayResult(getUpdateKey(entrant, "fixedwinresult"), "FixedWin", entrantResults)}</td>
                    <td align="right" className={getUpdateKey(entrant, "totewin")}>{displayHold(getUpdateKey(entrant, "totewin"), "ToteWin", entrantResults)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="total">
                  <td>Total</td>
                  <td></td>
                  <td align="right">{displayHold("fixedwintotal", "FixedWin", results)}</td>
                  {/*<td align="right"></td>*/}
                  <td align="right"></td>
                  <td align="right">{displayHold("totewintotal", "ToteWin", results)}</td>
                  {/*<td></td>
                  <td></td>*/}
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="col-md-1 panel total">
            <h4>&nbsp;</h4>
            <table className="table table-striped table-total" onMouseLeave={removeHover}>
              <thead>
                <tr>
                  <th className="header-overall">Overall Result</th>
                </tr>
              </thead>
              <tbody>
              {Object.entries(sortedResultsByEntrant).map(([entrant, entrantResults]) => (
                <tr className={getClassName(entrant)} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                  <td align="right" className={getUpdateKey(entrant, "overallresult")}>{displayResult(getUpdateKey(entrant, "overallresult"), "overallresult", entrantResults)}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <PlaceResultsComponent marketName={marketName} sortedResultsByEntrant={sortedResultsByEntrant} toggleHover={toggleHover} removeHover={removeHover} getUpdateKey={getUpdateKey} results={results} getClassName={getClassName} />
          <MultiResultsComponent marketName={marketName} sortedResultsByEntrant={sortedResultsByEntrant} toggleHover={toggleHover} removeHover={removeHover} getUpdateKey={getUpdateKey} results={results} getClassName={getClassName} />
        </div>
      </div>
    </div>
  )
}

export default ResultsByEntrantComponent