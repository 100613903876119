import React, { useContext } from "react";
import AppContext from "../context/AppContext";
import ResultsByEntrantComponent from "./ResultsByEntrantComponent";
import { Dna } from 'react-loader-spinner'
import { useEffect } from "react";

function ResultsComponent() {
  const {results, eventDetails, loading, eventEmitter, lastUpdate} = useContext(AppContext)

  let resultsByEntrant = {}
  
  useEffect(() => {
    eventEmitter.on('updated', (message) => {
      // flash the row that has the className from the message
      //console.log('updated ' + message)
      let row = document.getElementsByClassName(message)[0]
  
      if (typeof(row) !== 'undefined' && row.classList.contains("updated") === false) {
        row.classList.add("updated")
  
        setTimeout(() => {
          row.classList.remove("updated")
        }, 1000)
      }
    })
  }, [])

  results.forEach((result) => {
    let key = result.EntrantName
    if (resultsByEntrant[key] === undefined) {
      resultsByEntrant[key] = []
    }

    resultsByEntrant[key].push(result)
  })

  // sort resultsByEntrant by key
  let sortedResultsByEntrant = {}
  Object.keys(resultsByEntrant).sort((a, b) => {
    return a.localeCompare(b, 'en', {numeric: true})
  }).forEach(function(key) {
    sortedResultsByEntrant[key] = resultsByEntrant[key];
  });

  const displayEventName = (eventDetails) => {
    if (eventDetails.venue === undefined) {
      return ""
    }

    return `${eventDetails.venue} Race ${eventDetails.number}`
  }

  const showLastUpdated = () => {
    if (loading === true || typeof(results) === 'undefined' || results.length === 0) {
      return ""
    }

    let lastUpdateTime = new Date(lastUpdate).toString()

    return (
      <div className="last-updated">
        Last updated: {lastUpdateTime}
      </div>
    )
  }

  const showLoader = () => {
    if (loading === true) {
      return (
        <div className="loader">
          <Dna
            height="60"
            width="60"
            radius="9"
            color="green"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="loader-dna"
          />
          Loading...
        </div>
      )
    }
  }

  return (
    <div className="search-results">
      {showLastUpdated()}
      {showLoader()}
      <h2>{displayEventName(eventDetails)}</h2>
      {results.map((item, index) => {
      return <div className="row">
        <div className="col-sm-12">
          <ResultsByEntrantComponent marketGroup={item} />
        </div>
      </div>
      })}
      {/*<div className="row">
        <div className="col-md-6">
          <ResultsMultisComponent summary={summary} />
        </div>
        <div className="col-sm-6">
          <ResultsByProductComponent results={results} />
        </div>
  </div>*/}
    </div>
  )
}

export default ResultsComponent