import React, { useContext } from "react";
import AppContext from "../context/AppContext";
function MultiResultsComponent({marketName, sortedResultsByEntrant, toggleHover, removeHover, getUpdateKey, getClassName, results}) {
  const { displayHold, displayBonusHold, displayLiability } = useContext(AppContext)

  if (marketName !== "Final Field") {
    return ""
  }

  return (
    <div className="col-md-2 panel multi">
      <h4>Multis (Last leg)</h4>
      <table className="table table-striped table-multi" onMouseLeave={removeHover}>
        <thead>
          <tr>
            <th>Multi Hold</th>
            <th>Last Leg Liability</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(sortedResultsByEntrant).map(([entrant, entrantResults]) => (
            <tr className={getClassName(entrant)} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
              <td align="right" className={getUpdateKey(entrant, "multi")}>{displayHold(getUpdateKey(entrant, "multi"), "Multi", entrantResults)} {displayBonusHold(getUpdateKey(entrant, 'multi'), 'Multi', entrantResults)}</td>
              <td align="right" className={getUpdateKey(entrant, "multiliability")}>{displayLiability(getUpdateKey(entrant, "multiliability"), "Multi", entrantResults)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="total">
            <td align="right">{displayHold("multi", "Multi", results)}</td>
            <td align="right">{displayLiability("multi", "Multi", results)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default MultiResultsComponent