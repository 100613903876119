import logo from './logo.svg';
import './App.scss';
import NavBarComponent from "./components/NavBarComponent";
import ResultsComponent from "./components/ResultsComponent";
import FilterComponent from "./components/FilterComponent";
import { ChakraProvider } from '@chakra-ui/react'
import { AppContextProvider } from "./context/AppContext";

function App() {

  let clientID = Math.random().toString(36)
  

  return (
    <ChakraProvider>
      <AppContextProvider>
        <div className="App">
          <div className="container-fluid main">
            <div className="row">
              <div className="col-md-12">
                <NavBarComponent clientID={clientID} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ResultsComponent/>
              </div>
            </div>
          </div>
        </div>
      </AppContextProvider>
    </ChakraProvider>
  );
}

export default App;
