import {createContext, useState, useEffect} from 'react'
//import io from 'socket.io-client';
import Socket from '../services/socket'
import {EventEmitter} from 'events';
import CurrencyComponent from "../components/CurrencyComponent";

const AppContext = createContext({})
const eventEmitter = new EventEmitter()
eventEmitter.setMaxListeners(300)

let socket = null
let resultStore = {}
let resultsKeyMap = {}

let layAmounts = {}

let currentEventID = ''

const wsScheme = process.env.REACT_APP_LIABILITY_WS_SCHEME
const wsHost = process.env.REACT_APP_LIABILITY_WS_HOST

export const AppContextProvider = ({children}) => {

  const [eventID, setEventID] = useState("")
  const [clientID, setClientID] = useState("")
  const [results, setResults] = useState([])
  const [eventDetails, setEventDetails] = useState([])
  const [loading, setLoading] = useState([])
  const [lastUpdate, setLastUpdate] = useState(0)
  const [scratchings, setScratchings] = useState({})
  const [refresh, setRefresh] = useState(false)

  const handleAggregation = async (aggregation) => {
    await fetchResults(eventID, aggregation)
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    let eventID = e.target[0].value
    setEventID(eventID)
    currentEventID = eventID
    await fetchResults(eventID, 'entrant')
  }

  let intervals = []

  const onConnect = () => {
    console.log("connected to server")
  }

  const onDisconnect = () => {
    console.log("disconnected from server")
    
    for (let i = 0; i <= intervals.length; i++) {
      window.clearInterval(intervals[i])
    }

    // need to advise a refresh
    setRefresh(true)
  }

  const onHeader = (message) => {
    //message = atob(message[1])
    //console.log("message received: " + message)
  }

  const onEvent = (message) => {
    setEventDetails(message)
  }

  const displayBonusHold = (key, product, entrantItems) => {

    if (true) {
      return ""
    }

    if (resultsKeyMap[key] === undefined) {
      resultsKeyMap[key] = {
        Hold: 0,
        Liability: 0
      }
    }

    let holds = Object.entries(entrantItems).map(([entrant, results]) => results['ProductHolds'][product])

    if (typeof(holds[0]) == 'undefined') {
      return "-"
    }

    holds = holds.filter((h) => typeof(h) != "undefined")

    let hold = holds.reduce((a, b) => a + b.BonusHold, 0)
    if (hold === 0) {
      return "-"
    }

    let oldHold = roundOff(resultsKeyMap[key].BonusHold)
    let newHold = roundOff(hold)
    
    if (Math.abs(oldHold - newHold) > 0) {
    //if (oldHold !== newHold) {
      console.log("updating hold for " + key + " from " + oldHold + " to " + newHold)
      eventEmitter.emit('updated', key)
    }

    resultsKeyMap[key].BonusHold = hold

    return ` ($${hold.toLocaleString(undefined, {maximumFractionDigits: 0})})`
  }

  const displayHold = (key, product, entrantItems) => {
    if (resultsKeyMap[key] === undefined) {
      resultsKeyMap[key] = {
        Hold: 0,
        Liability: 0
      }
    }

    let holds = Object.entries(entrantItems).map(([entrant, results]) => results['ProductHolds'][product])
    holds = holds.filter((h) => typeof(h) != "undefined")

    if (key === "3-Miss-Sheaf-multi") {
      console.log(holds[0].TotalHold)
      console.log(holds[0].TotalLiability)
    }

    if (holds.length === 0) {
      return "-"
    }

    let hold = holds.reduce((a, b) => a + b.TotalHold, 0)
    if (hold === 0) {
      return "-"
    }

    let oldHold = roundOff(resultsKeyMap[key].TotalHold)
    let newHold = roundOff(hold)
    
    if (Math.abs(oldHold - newHold) > 0) {
    //if (oldHold !== newHold) {
      console.log("updating hold for " + key + " from " + oldHold + " to " + newHold)
      eventEmitter.emit('updated', key)
    }

    resultsKeyMap[key].TotalHold = hold

    return `$${hold.toLocaleString(undefined, {maximumFractionDigits: 0})}`
  }

  const displayLiability = (key, product, entrantItems) => {
    if (resultsKeyMap[key] === undefined) {
      resultsKeyMap[key] = {
        Hold: 0,
        Liability: 0
      }
    }

    if (typeof(entrantItems) === 'undefined') {
      return "-"
    }

    let liability = 0

    let holds = Object.entries(entrantItems).map(([entrant, results]) => results['ProductHolds'][product])
    holds = holds.filter((h) => typeof(h) != "undefined")

    if (holds.length === 0) {
      return "-"
    }

    liability = holds.reduce((a, b) => a + b.TotalLiability, 0)

    let oldLiability = roundOff(resultsKeyMap[key].TotalLiability)
    let newLiability = roundOff(liability)

    if (oldLiability !== newLiability) {
      eventEmitter.emit('updated', key)
    }

    resultsKeyMap[key].TotalLiability = liability

    return `$${liability.toLocaleString(undefined, {maximumFractionDigits: 0})}`
  }

  const displayResult = (key, product, entrantItems) => {
    let oldItem = resultsKeyMap[key]
    let result = 0

    if (typeof(oldItem) === 'undefined') {
      oldItem = {
        Result: 0
      }

      resultsKeyMap[key] = oldItem
    }

    if (product === 'overallresult') {
      // only sum results for fixed win and place, and then add the synthetic tote results
      //let fixedWinItems = entrantItems.filter((result) => { let typeFlag = result["TypeFlag"].slice(-11); return typeFlag === "1:0:1:0:0:0" || typeFlag === "1:0:0:1:0:0" })
      let holds = Object.entries(entrantItems).map(([entrant, results]) => results['ProductHolds']['FixedWin'])
      let toteHolds = Object.entries(entrantItems).map(([entrant, results]) => results['ProductHolds']['ToteWin'])
      let placeHolds = Object.entries(entrantItems).map(([entrant, results]) => results['ProductHolds']['FixedPlace'])
      let totePlaceHolds = Object.entries(entrantItems).map(([entrant, results]) => results['ProductHolds']['TotePlace'])

      holds = holds.filter((h) => typeof(h) != "undefined")
      toteHolds = toteHolds.filter((h) => typeof(h) != "undefined")
      placeHolds = placeHolds.filter((h) => typeof(h) != "undefined")
      totePlaceHolds = totePlaceHolds.filter((h) => typeof(h) != "undefined")

      result = holds.reduce((a, b) => a + b.TotalResult, 0)
      result += toteHolds.reduce((a, b) => a + b.TotalResult, 0)
      //result += placeHolds.reduce((a, b) => a + b.TotalResult, 0)
      //result += totePlaceHolds.reduce((a, b) => a + b.TotalResult, 0)
      //result = result * -1

      //result = fixedWinItems.reduce((a, b) => a + b['ProductHolds'][product].TotalResult, 0)
    } else {
      let holds = Object.entries(entrantItems).map(([entrant, results]) => results['ProductHolds'][product])

      if (typeof(holds[0]) === "undefined") {
        return "-"
      }

      holds = holds.filter((h) => typeof(h) != "undefined")

      // Is this a tote item? If so, we want to approximate result based on the average lay amount.
      let keyParts = key.split('-')
      
      if (keyParts[keyParts.length - 1] === 'totewinresult') {
        let saddle = keyParts[0]
        let avgLay = layAmounts[saddle]
        if (typeof(avgLay) === 'undefined') {
          return "-"
        }

        // get the total tote hold from the full results
        let toteHold =  Object.entries(results).map(([entrant, resultItems]) => resultItems['ProductHolds']['ToteWin']).reduce((a, b) => a + b.TotalHold, 0)
        let thisHold = entrantItems.reduce((a, b) => a + b['ProductHolds'][product].TotalHold, 0)

        if (thisHold === 0) {
          return "-"
        }

        let liability = entrantItems.reduce((a, b) => a + b['ProductHolds'][product].TotalHold, 0) * avgLay
        result = toteHold - liability
      } else {
        result = entrantItems.reduce((a, b) => a + b['ProductHolds'][product].TotalResult, 0)
      }
    }
    
    let oldResult = roundOff(oldItem.TotalResult)
    result = roundOff(result)
   
    if (oldResult !== result) {
      eventEmitter.emit('updated', key)
    }

    resultsKeyMap[key].TotalResult = result

    let resultClass = "result"
    if (result < 0) {
      resultClass = "result negative"
    }

    return CurrencyComponent({amount: result})
  }

  const displayAvgLay = (key, product, entrantItems) => {
    if (typeof(entrantItems[0]['ProductHolds'][product]) === 'undefined') {
      return "-"
    }

    let avgLay = entrantItems[0]['ProductHolds'][product]['AverageLay']

    return `$${avgLay.toLocaleString(undefined, {maximumFractionDigits: 2})}`
  }

  const roundOff = (number) => {
    return Math.ceil(number)
  }

  const roundToTwo = (number) => {
    return (Math.ceil(number*100.0)/100.0).toFixed(2)
  }
  
  const onScratchings = (messages) => {
    Object.keys(messages).forEach((key) => {
      scratchings[key] = messages[key]
    })

    setScratchings(scratchings)
  }

  const onRows = (messages) => {
    setLoading(false)
    let tmpResults = Object.assign({}, resultStore)
    let resultArray = []

    Object.entries(messages).forEach(([market, entrants]) => {
      let marketObj = {
        market: market,
        entrants: []
      }

      Object.entries(entrants).forEach(([key, value]) => {
        marketObj.entrants.push(value)
      })

      if (market === "Final Field") {
        resultArray.unshift(marketObj)
      } else {
        resultArray.push(marketObj)
      }
    })
    
    console.log(resultArray)
    /*
    for (let i = 0; i < messages.length; i++) {
      let message = messages[i]      
      let entrantKey = message['EntrantID']
      let newResult = message
      message.key = entrantKey

      tmpResults[entrantKey] = newResult
    }

    resultStore = tmpResults

    
    Object.entries(tmpResults).forEach(([key, value]) => {   
      resultArray.push(value)
    })

    resultArray.sort((a, b) => {
      return a.EntrantName.localeCompare(b.EntrantName, 'en')
    })*/
 
    setResults(resultArray)
  }

  const disconnect = (socket) => {
    socket.disconnect()
  }

  const fetchResults = function(requestEventID, aggregation) {

    setLastUpdate(Date.now())
    setLoading(true)

    if (requestEventID !== currentEventID) {
      console.log("new event, clearing")
      setResults([])
      resultStore = {}
      resultsKeyMap = {}
    }

    setEventID(eventID)
    currentEventID = eventID

    let req = {
      aggregation: aggregation,
      event_id: requestEventID,
    }

    socket.emit('fetchResults', req)
  }
  
  useEffect(() => {
    if (socket !== null) {
      return
    }

    let clientID = Math.random().toString(36)

    console.log("connect websocket called")
    // establish websocket connection to backend server.
    let host = window.location.host.split(':')[0]
    const wsURL = wsScheme + "://" +host + "/socket.io?client_id=" + clientID

    let ws = new WebSocket(wsURL);

    // create and assign a socket to a variable.
    socket = new Socket(ws);

    // handle connect and discconnect events.
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    // event listener to handle 'hello' from a server
    socket.on('header', onHeader);
    socket.on('rows', onRows);
    socket.on('event', onEvent);
    socket.on('scratchings', onScratchings);

    socket.on('open', () => {
      console.log('connection open')
    })

    socket.on('updated_ts', (message) => {
      let updateTS = Date.parse(message)
      if (updateTS > lastUpdate) {
        setLastUpdate(updateTS)
      }
    })

    socket.on('heartbeat', () => {
      console.log('ping')
      socket.emit('pong', 0)
    })

    }, [clientID])
    
  return <AppContext.Provider value={{
    handleSearch,
    fetchResults,
    eventID,
    setEventID,
    disconnect,
    clientID,
    setClientID,
    results,
    setResults,
    eventDetails,
    setEventDetails,
    handleAggregation,
    loading,
    setLoading,
    eventEmitter,
    displayHold,
    displayBonusHold,
    displayLiability,
    displayResult,
    displayAvgLay,
    lastUpdate,
    scratchings
  }}>
    {children}
  </AppContext.Provider>
}

export default AppContext