import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import AppContext from "../context/AppContext";

function NavBarComponent({clientID}) {

  const {handleSearch, disconnect} = useContext(AppContext)

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">Liability Portal</Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Form onSubmit={handleSearch} className="d-flex" style={{width: '99%'}}>
                            <Form.Control
                                type="search"
                                placeholder="Search by Event ID"
                                className="me-2"
                                aria-label="Search"
                            />
                        </Form>
            <button onClick={disconnect}>Disconnect</button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavBarComponent