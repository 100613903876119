import React, { useContext } from "react";
import AppContext from "../context/AppContext";

function PlaceResultsComponent({marketName, sortedResultsByEntrant, toggleHover, removeHover, getUpdateKey, getClassName, results}) {
  const { displayHold } = useContext(AppContext)

  if (marketName !== "Final Field") {
    return ""
  }

  return (
    <div className="col-md-4 panel place">
      <h4>Place</h4>
      <table className="table table-striped table-place" onMouseLeave={removeHover}>
        <thead>
          <tr>
            <th>Fixed Hold</th>
            {/*<th>Fixed Liability</th>
            <th>Fixed Result</th>*/}
            <th>Tote Hold</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(sortedResultsByEntrant).map(([entrant, entrantResults]) => (
            <tr className={getClassName(entrant)} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
              <td align="right" className={getUpdateKey(entrant, "fixedplace")}>{displayHold(getUpdateKey(entrant, "fixedplace"), "FixedPlace", entrantResults)}</td>
              {/*<td align="right" className={getUpdateKey(entrant, "fixedplaceliability")}>{displayLiability(getUpdateKey(entrant, "fixedplaceliability"), entrantResults['ProductHold'].filter((r) => {return r["TypeFlag"].slice(-11) === "1:0:0:1:0:0"}))}</td>
              <td align="right" className={getUpdateKey(entrant, "fixedplaceresult")}>{displayResult(getUpdateKey(entrant, "fixedplaceresult"),   entrantResults['ProductHold'].filter((r) => {return r["TypeFlag"].slice(-11) === "1:0:0:1:0:0"}))}</td>*/}
              <td align="right" className={getUpdateKey(entrant, "toteplace")}>{displayHold(getUpdateKey(entrant, "toteplace"), "TotePlace", entrantResults)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="total">
            <td align="right">{displayHold("fixedplacetotal", "FixedPlace", results)}</td>
            {/*<td></td>
            <td></td>*/}
            <td align="right">{displayHold("toteplacetotal", "TotePlace", results)}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default PlaceResultsComponent